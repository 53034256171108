import { createRouter, createWebHashHistory } from 'vue-router'
import QRCode from '../views/operation/qrcode.vue'

const routes = [
  {
    path: '/',
    name: 'qrcode',
    component: QRCode
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
